<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <user-view-user-permissions-card />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import UserViewUserPermissionsCard from '@/views/apps/user/users-view/UserViewUserPermissionsCard'

export default {
  name: 'AccountSettingRoles',
  components: {
    UserViewUserPermissionsCard,
    BCard,
  },
}
</script>

<style scoped>

</style>
