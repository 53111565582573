<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- dane firmy -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="MoreHorizontalIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Company Data') }}</span>
      </template>

      <account-setting-company-info
        v-if="options.companyInfo"
        :general-data="options.companyInfo"
      />
    </b-tab>

    <!-- Uzytkownicy  / options.users-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Users') }}</span>
      </template>

      <account-setting-users
        v-if="options.companyInfo"
        :general-data="options.companyInfo"
      />
    </b-tab>

    <!-- Uzytkownicy - ACL  / options.users-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          ACL
        </span>
      </template>

      <account-setting-roles />
    </b-tab>

    <!-- Kategorie  / options.categories-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="GridIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Kategorie</span>
      </template>

      <account-setting-categories
        v-if="options.companyInfo"
        :general-data="options.companyInfo"
      />
    </b-tab>

    <!-- Konfiguracja / options.configuration-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Konfiguracja</span>
      </template>

      <account-setting-configuration
        v-if="options.companyInfo"
        :general-data="options.companyInfo"
      />
    </b-tab>

    <!-- Drukowanie  / options.printing-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="PrinterIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Drukowanie</span>
      </template>

      <account-setting-printing
        v-if="options.companyInfo"
        :general-data="options.companyInfo"
      />
    </b-tab>

    <!-- Pozostałe / options.debt-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Windykacja</span>
      </template>

      <account-setting-debt
        v-if="options.debt"
        :general-data="options.debt"
      />
    </b-tab>

    <!-- Pozostałe / options.others-->
    <b-tab>
      <template #title>
        <feather-icon
          icon="AlignJustifyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Pozostałe</span>
      </template>

      <account-setting-others
        v-if="options.companyInfo"
        :general-data="options.companyInfo"
      />
    </b-tab>

    <!-- Rodzaje dokumentów / options.invoiceTypes -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="PaperclipIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Rodzaje dokumentów</span>
      </template>

      <account-setting-invoices-types
        v-if="options.invoiceTypes"
        :general-data="options.invoiceTypes"
      />
    </b-tab>

    <!-- Numeracja dokumentów / options.invoiceTypes -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="Edit2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Numeracja dokumentów</span>
      </template>

      <AccountSettingDocsPrefixes
        v-if="options.docsPrefixes"
        :general-data="options.docsPrefixes"
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingCompanyInfo from './AccountSettingCompanyInfo.vue'
import AccountSettingUsers from './AccountSettingUsers.vue'
import AccountSettingCategories from './AccountSettingCategories.vue'
import AccountSettingConfiguration from './AccountSettingConfiguration.vue'
import AccountSettingPrinting from './AccountSettingPrinting.vue'
import AccountSettingOthers from './AccountSettingOthers.vue'
import AccountSettingInvoicesTypes from './AccountSettingInvoicesTypes.vue'
import AccountSettingDocsPrefixes from './AccountSettingDocsPrefixes.vue'
import AccountSettingDebt from './AccountSettingDebt.vue'
// eslint-disable-next-line import/extensions
import AccountSettingRoles from '@/views/pages/account-setting/AccountSettingRoles'

export default {
  components: {
    AccountSettingRoles,
    BTabs,
    BTab,
    AccountSettingCompanyInfo,
    AccountSettingUsers,
    AccountSettingCategories,
    AccountSettingConfiguration,
    AccountSettingPrinting,
    AccountSettingOthers,
    AccountSettingInvoicesTypes,
    AccountSettingDocsPrefixes,
    AccountSettingDebt,

  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
