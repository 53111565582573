<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Windykacja
      </h6>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Włącz obsługę windykacji</span>
        </b-form-checkbox>

      </b-col>

    </b-row>

    <!-- Kiedy wysyłać wiadomości ? -->
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Kiedy wysyłać wiadomości ?
      </h6>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="mon"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Poniedziałek</span>
        </b-form-checkbox>

      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="tue"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Wtorek</span>
        </b-form-checkbox>

      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="wed"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Środa</span>
        </b-form-checkbox>

      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="wedn"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Czwartek</span>
        </b-form-checkbox>

      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="fri"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Piątek</span>
        </b-form-checkbox>

      </b-col>

    </b-row>

    <!-- Wysyłaj przypomnienia-->
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Wysyłaj przypomnienia
      </h6>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="rem2"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Włącz wysyłanie przypomnień</span>
        </b-form-checkbox>

      </b-col>

      <b-col
        cols="4"
        class="mb-2"
      >
        <b-form-group
          label="Minimalna kwota do zapłaty"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="minimalna kwota do zapłaty"
          />
        </b-form-group>

      </b-col>

      <b-col
        cols="4"
        class="mb-2"
      >
        <b-form-group
          label="Dni przed upływem terminu"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="Dni przed upływem terminu"
          />
        </b-form-group>

      </b-col>

    </b-row>

    <!-- Wysyłaj wezwania do zapłaty-->
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Wysyłaj wezwania do zapłaty
      </h6>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="rem"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Włącz wysyłanie wezwań do zapłaty</span>
        </b-form-checkbox>

      </b-col>

      <b-col
        cols="4"
        class="mb-2"
      >
        <b-form-group
          label="Minimalna kwota do zapłaty"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="minimalna kwota do zapłaty"
          />
        </b-form-group>

      </b-col>

      <b-col
        cols="4"
        class="mb-2"
      >
        <b-form-group
          label="Dni po upływie"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="Dni po upływie"
          />
        </b-form-group>

      </b-col>

      <b-col
        cols="4"
        class="mb-2"
      >
        <b-form-group
          label="Co ile dni powtarzać"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="Co ile dni powtarzać"
          />
        </b-form-group>

      </b-col>

      <b-col
        cols="4"
        class="mb-2"
      >
        <b-form-group
          label="Zakończ windykacje po wysłaniu"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="5 wezwań"
          />
        </b-form-group>

      </b-col>

    </b-row>

  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormCheckbox, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
s
