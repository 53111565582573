<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="onSubmit">
      <!-- faktura VAT -->
      <b-row v-for="(item, index) in fields">
        <b-col
          sm="2"
          class="d-flex align-items-center"
        >
          <p class="mb-0 pb-0">
            {{item.text}}
          </p>

        </b-col>
        <!-- prefix -->
        <b-col sm="2">
          <b-form-group
            label="Prefix/ symbol"
            label-for="account-username"
          >
            <b-form-input
              v-model="item.prefix"
              placeholder="FV"
              name="username"
            />
          </b-form-group>
        </b-col>

        <!-- pierwszy człon -->
        <b-col sm="2">
          <b-form-group
            label="Pierwszy człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="item.first_part"
              :options="options"
            />

          </b-form-group>
        </b-col>

        <!-- drugi człon -->
        <b-col sm="2">
          <b-form-group
            label="Drugi człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="item.second_part"
              :options="options2"
            />

          </b-form-group>
        </b-col>

        <!-- trzeci człon -->
        <b-col sm="2">
          <b-form-group
            label="Trzeci człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="item.third_part"
              :options="options3"
            />

          </b-form-group>
        </b-col>

        <!-- czwarty człon -->
        <b-col sm="2">
          <b-form-group
            label="Czwarty człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="item.fourth_part"
              :options="options3"
            />

          </b-form-group>
        </b-col>

      </b-row>

      <!-- Nota księgowa -->
      <b-row>
        <b-col
          sm="2"
          class="d-flex align-items-center"
        >
          <p class="mb-0 pb-0">
            Nota księgowa
          </p>

        </b-col>
        <!-- prefix -->
        <b-col sm="2">
          <b-form-group
            label="Prefix/ symbol"
            label-for="account-username"
          >
            <b-form-input
              placeholder="FV"
              name="username"
            />
          </b-form-group>
        </b-col>

        <!-- pierwszy człon -->
        <b-col sm="2">
          <b-form-group
            label="Pierwszy człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected"
              :options="options"
            />

          </b-form-group>
        </b-col>

        <!-- drugi człon -->
        <b-col sm="2">
          <b-form-group
            label="Drugi człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected2"
              :options="options2"
            />

          </b-form-group>
        </b-col>

        <!-- trzeci człon -->
        <b-col sm="2">
          <b-form-group
            label="Trzeci człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected3"
              :options="options3"
            />

          </b-form-group>
        </b-col>

        <!-- czwarty człon -->
        <b-col sm="2">
          <b-form-group
            label="Czwarty człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected3"
              :options="options3"
            />

          </b-form-group>
        </b-col>

      </b-row>

      <!-- Własny dokument nieksięgowy -->
      <b-row>
        <b-col
          sm="2"
          class="d-flex align-items-center"
        >
          <p class="mb-0 pb-0">
            Własny dokument nieksięgowy
          </p>

        </b-col>
        <!-- prefix -->
        <b-col sm="2">
          <b-form-group
            label="Prefix/ symbol"
            label-for="account-username"
          >
            <b-form-input
              placeholder="FV"
              name="username"
            />
          </b-form-group>
        </b-col>

        <!-- pierwszy człon -->
        <b-col sm="2">
          <b-form-group
            label="Pierwszy człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected"
              :options="options"
            />

          </b-form-group>
        </b-col>

        <!-- drugi człon -->
        <b-col sm="2">
          <b-form-group
            label="Drugi człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected2"
              :options="options2"
            />

          </b-form-group>
        </b-col>

        <!-- trzeci człon -->
        <b-col sm="2">
          <b-form-group
            label="Trzeci człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected3"
              :options="options3"
            />

          </b-form-group>
        </b-col>

        <!-- czwarty człon -->
        <b-col sm="2">
          <b-form-group
            label="Czwarty człon"
            label-for="account-username"
          >
            <b-form-select
              v-model="selected3"
              :options="options3"
            />

          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
          >
            Zapisz
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BFormFile, BFormSelect, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import Swal from 'sweetalert2'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      selected: null,
      selected2: null,
      selected3: null,
      fields: [
        { text: 'Faktura VAT', doc_type: 'Vat', prefix: '', first_part: '', second_part: '', third_part: '', fourth_part: '' },
        { text: 'Proforma', doc_type: 'Proforma', prefix: '', first_part: '', second_part: '', third_part: '', fourth_part: '' },
        { text: 'Faktura VAT marża', doc_type: 'ProfitMargin', prefix: '', first_part: '', second_part: '', third_part: '', fourth_part: '' },
      ],
      options: [
        { value: null, text: 'Numer z zerami' },
        { value: 'a', text: 'Miesiąc' },
        { value: 'b', text: 'Rok kalendarzowy' },
        { value: { C: '3PO' }, text: 'Losowy ciąg 5 znaków' },
      ],
      options2: [
        { value: null, text: 'Numer z zerami' },
        { value: 'a', text: 'Miesiąc' },
        { value: 'b', text: 'Rok kalendarzowy' },
        { value: { C: '3PO' }, text: 'Losowy ciąg 5 znaków' },
      ],
      options3: [
        { value: null, text: 'Numer z zerami' },
        { value: 'a', text: 'Miesiąc' },
        { value: 'b', text: 'Rok kalendarzowy' },
        { value: { C: '3PO' }, text: 'Losowy ciąg 5 znaków' },
      ],
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    onSubmit() {
    	const data = {
    		docsprefixes: this.fields
    	}
      axiosIns.post('DocsPrefix', data).then(() => {
        Swal.fire(
          'Pomyślnie dodano Fakturę',
          '',
          'success',
        )
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
